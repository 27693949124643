import flag from "./icons/flag.png";
import mine from "./icons/mine.png";
import timer from "./icons/timer.png";
import losing from "./sounds/losing.mp3";
import victory from "./sounds/victory.mp3";

export const img = {
    flag,
    timer,
    mine,
}

export const snd = {
    losing,
    victory,
}

